<template>
    <div class="app-container">
        <div class="filter-container">
            <el-button class="filter-item" type="primary" size="small" @click="$router.go(-1)">返回</el-button>

            <el-button class="filter-item" type="primary" size="small" @click="handleCreate">添加</el-button>

            <el-select v-model="listQuery.pay_state" placeholder="提现状态" size="small" class="filter-item" style="width: 120px" clearable>
                <el-option label="支付成功" value="00" />
                <el-option label="支付失败" value="01" />
                <el-option label="处理中" value="03" />
            </el-select>

            <el-button class="filter-item" type="primary" size="small" @click="handleFilter">搜索</el-button>
        </div>

        <div class="filter-container">
            <el-row :gutter="20" >
                <el-col :sm="8">
                    <el-card class="box-card">
                        <div class="value" style="color:#67C23A;">{{balanceInfo ? balanceInfo.availableBalance : '客户不存在'}}</div>
                        <div class="subtitle">可用金额(单位：分)</div>
                    </el-card>
                </el-col>
                <el-col :sm="8">
                    <el-card class="box-card">
                        <div class="value" style="color:#E6A23C;">{{balanceInfo ? balanceInfo.floatBalance : '客户不存在'}}</div>
                        <div class="subtitle">在途金额(单位：分)</div>
                    </el-card>
                </el-col>
                <el-col :sm="8">
                    <el-card class="box-card">
                        <div class="value" style="color:#F56C6C;">{{balanceInfo ? balanceInfo.frozenBalance : '客户不存在'}}</div>
                        <div class="subtitle">冻结金额(单位：分)</div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="ID" align="center" width="80">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="商户名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.merchant ? scope.row.merchant.merchant_name : '' }}
                </template>
            </el-table-column>

            <el-table-column label="提现单号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.withdrawal_no }}
                </template>
            </el-table-column>

            <el-table-column label="提现金额(单位：分)" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.amount }}
                </template>
            </el-table-column>

            <el-table-column label="手续费(单位：分)" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.procedure_fee }}
                </template>
            </el-table-column>

            <el-table-column label="到账金额(单位：分)" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.actual_fee }}
                </template>
            </el-table-column>

            <el-table-column label="提现状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.pay_state === '00'">支付成功</el-tag>
                    <template v-else-if="scope.row.pay_state === '01'">
                        <el-tag type="danger">支付失败</el-tag>
                        <div>原因说明：{{scope.row.channel_query_msg}}</div>
                    </template>
                    <el-tag type="warning" v-else-if="scope.row.pay_state === '03'">处理中</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="银行卡号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.bank_card_no }}
                </template>
            </el-table-column>

            <el-table-column label="银行名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.bank_name }}
                </template>
            </el-table-column>

            <el-table-column label="创建时间" min-width="120" align="center">
                <template slot-scope="scope">
                    {{ scope.row.created_at }}
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="right" label-width="150px">
                <el-form-item label="提现金额(单位：分)" prop="amount">
                    <el-input-number v-model="form.amount" :min="0" :precision="0"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    merchant_id: '',
                    pay_state: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '编辑',
                    create: '添加',
                },
                btnLoading: false,
                form:{
                    school_id: '',
                    merchant_id: '',
                    amount: 0,
                },
                rules: {
                    amount: [{ required: true, message: '提现金额不能为空', trigger: 'change' }],
                },
                merchant_id: '',
                balanceInfo: null,
            };
        },
        created() {
            this.merchant_id = this.$route.query.merchant_id-0;
            this.getList();
            this.getMerchantBalanceInfo();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
                this.getMerchantBalanceInfo();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listQuery.merchant_id = this.merchant_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/merchant/withdrawalList",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            getMerchantBalanceInfo() {
                request({
                    url: "/api/backend/merchant/balanceInfo",
                    method: "get",
                    params: {
                        school_id: this.school_id,
                        merchant_id: this.merchant_id,
                    }
                }).then(response => {
                    this.balanceInfo = response.data;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    school_id: '',
                    merchant_id: '',
                    amount: 0,
                }
                this.btnLoading = false
            },
            handleCreate() {
                this.resetForm()
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            handleUpdate(row) {
                this.resetForm()
                this.form = Object.assign({}, row)
                this.dialogStatus = 'update'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData() {
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.form.school_id = this.school_id;
                }

                if (!this.merchant_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择商户"
                    });
                    return;
                } else {
                    this.form.merchant_id = this.merchant_id;
                }

                if (this.form.amount <= 0) {
                    this.$message({
                        type: "warning",
                        message: "提现金额必须大于0"
                    });
                    return;
                }

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/merchant/withdrawalApply',
                            method: 'post',
                            data: this.form
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                            this.getMerchantBalanceInfo();
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
        }
    };
</script>


<style rel="stylesheet/scss" lang="scss" scoped>
    .box-card {
        text-align: center;
        .value {
            color: #546DFE;
            font-weight: bold;
            font-size: 26px;
        }
        .subtitle {
            font-size: 12px;
            color: gray;
            margin-top: 6px;
        }
    }
    .el-row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .el-col {
        margin-bottom: 20px;
    }
</style>
